/* add css module styles here (optional) */

* {
  font-family: 'Work Sans', sans-serif;
}

._3ybTi {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

._1C_Pk {
  font-size: 0.65em;
}

._3XDT1 {
  font-size: 1.5em;
}

._3jQcb {
  font-weight: 600;
}

._2xY_E {
  font-size: 1em;
  border: 1px solid #9B9B9B;
  border-radius: 0 !important;
  border-top: none;
  border-right: none;
  padding: 0.5em;
  margin: 0 0 1em 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
}

._2xY_E:focus {
  outline: none;
}

._15qAt {
  text-align: left;
  background-color: none;
}
._2_bJC {
  width: 3.75em;
  transform-origin: center;
  -webkit-animation: _3UW17 2s linear infinite;
          animation: _3UW17 2s linear infinite;
  position: absolute;
}

._WLVru {
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  -webkit-animation: _3zFDp 1.5s ease-in-out infinite;
          animation: _3zFDp 1.5s ease-in-out infinite;
}

._2jPlY {
  stroke: white;
}

@-webkit-keyframes _3UW17 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes _3UW17 {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes _3zFDp {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}

@keyframes _3zFDp {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
._1eRcz {
  font-size: 1em;
  background-color: black;
  color: white;
  height: 3em;
  outline: none !important;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}

._1eRcz img {
  height: 2em;
  width: auto;
  margin-right: 1em;
}

._2ZbCS {
  background-color: black;
  color: white;
  border: none;
}

._2ZbCS:hover {
  /* background-color: #262626; */
  border: 1px solid #8EF1FF;
  box-shadow: 2px 2px 0 0 rgba(0,0,0,0.5)
}

._1cxTG {
  background-color: white;
  border: 1px solid black;
  color: black;
}

._1cxTG:hover {
  /* background-color: black;
  color: white; */
  box-shadow: 2px 2px 0 0 rgba(0,0,0,0.5);
}
._DaUdk {
  display: flex;
  background-color: rgb(65, 65, 65);
  height: 6em;
  padding-left: 1.5em;
  box-sizing: border-box;
}

._DaUdk ._1wvgH {
  width: 6em;
  padding: 10px;
  margin-right: 1.5em;
  box-sizing: border-box;
}

._DaUdk ._1wvgH img {
  height: 100%;
  width: auto;
}

._DaUdk ._5OxfT {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% + 5.5em);
  padding: 1.5em 0;
}

._DaUdk ._3UGTz {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5em 0;
}

._DaUdk p {
  color: white;
  margin: 0;
}

._DaUdk ._1t2i1 {
  margin-bottom: 0.25em !important;
}

._2ABVC {
  color: #8B8B8B !important;
}

._36f_R {
  font-size: 1.5em;
}


._JcqeL {
  position: relative;
  background-color: white;
  display: flex;
  border-radius: 200px 0 0 200px;
  height: 5em;
  width: calc(100% - 4em);
  min-width: 32em;
  margin-bottom: 1em;
  padding: 5px 2em 5px 5px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0;
  transition: 0.3s;
}

._1Mq4F {
  background-color: #8EF1FF;
  border-radius: 1em;
  font-size: 0.75em;
  /* width: 10em; */
  display: inline-block;
  padding: 0 1em;
  margin-top: 0;
}

._JcqeL:hover {
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

._31piP {
  border: 1px solid #000000;
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

._JcqeL p {
  margin: 0.25em 0;
}

._JcqeL ._1WnSz {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  width: calc(5em - 10px);
  height: calc(5em - 10px);
  min-width: calc(5em - 10px);
  background-color: rgb(68, 68, 68);
}

._JcqeL ._244t1 {
  height: 100%;
  width: auto;
  border-radius: 100%;
}

._JcqeL ._1WnSz p {
  font-size: 1.5em;
  color: white;
}

._JcqeL ._R60wb {
  margin-left: 2em;
  width: calc(100% - 18em);
  align-items: flex-start;
  /* min-width: 21em; */
}

._JcqeL ._R60wb, ._JcqeL ._yDkmn, ._JcqeL ._2G4mo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._JcqeL ._yDkmn p:first-child, ._JcqeL ._2G4mo p:first-child {
  font-size: 0.75em;
  font-weight: 600;
}

._JcqeL ._yDkmn, ._JcqeL ._2G4mo {
  width: 7em;
  margin-left: 1em;
}

._JcqeL ._168lF {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  border-radius: 2.5em;
  background-color: black;
  position: absolute;
  top: -1.25em;
  left: 4em;
}

._JcqeL ._168lF p {
  color: white;
}


._15mTB {
  position: fixed;
  background-color: white;
  display: flex;
  border-radius: 200px 0 0 200px;
  height: 5em;
  min-width: 43.75em;
  margin-bottom: 1em;
  padding: 5px 1.5em 5px 5px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 5px 5px 0 0 rgb(204, 204, 204);
  z-index: 4;
}

._vZNfx {
  text-transform: capitalize;
}

._15mTB p {
  margin: 0;
}

._15mTB ._2eBt3 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  width: calc(5em - 10px);
  height: calc(5em - 10px);
  min-width: calc(5em - 10px);
  background-color: rgb(68, 68, 68);
}

._15mTB ._3kZHY {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

._G4nit {
  display: flex;
  justify-content: center;
  align-items: center;
}

._1zDwM {
  box-sizing: border-box;
  padding: 0.5em;
  box-shadow: -5px -5px 5px 0 rgba(255,255,255,0.5), 0 -2px 4px 0 rgba(255,255,255,0.5), 0 2px 3px 0 rgba(212,212,212,0.5), 5px 5px 5px 0 rgba(202,202,202,0.5);
  border-radius: 3em;
  display: flex;
  align-items: center;
  height: 3em;
  width: 4em;
}

._1zDwM svg {
  height: 100%;
  width: auto;
}

._1zDwM p {
  margin: 0;
}

._15mTB ._2eBt3 p {
  font-size: 1.5em;
  color: white;
}

._15mTB ._3j4tg {
  width: 50%;
  margin-left: 1em;
}

._15mTB ._3j4tg, ._15mTB ._2SwEB {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._15mTB ._2SwEB {
  font-size: 14px;
  margin: 0 2em;
}

._2fRwJ {
  font-size: 1.5em;
}
._1QBQz {
  padding: 0.75em;
  box-sizing: border-box;
  height: 5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  margin-bottom: 5px;
  position: relative;
}

._1QBQz img {
  height: 1.5em;
  width: auto;
}

._33XAf {
  border-left: 3px solid black !important; /* overwrite inline style */
}

._1QBQz p {
  margin: 0;
}

._1QBQz ._zrxsn {
  font-size: 0.65em;
  margin: 1px 10px 0 0;
}

._1QBQz ._1juOL {
  font-size: 1.5em;
}

._1QBQz ._1OuvZ {
  font-size: 2em;
  font-weight: 500;
}

._2KlpY {
  display: flex;
  justify-content: space-between;
}

._3fIIW {
  align-items: center;
}

._2-UTh {
  background-color: white;
  border-left: 3px solid white;
}

._2XBTc {
  background-color: black;
  border-color: white;
  border-color: black;
}

._2XBTc p {
  color: white;
}

._2uPYR {
  border-left: 3px solid white !important;
}
/* The switch - the box around the slider */
._3By-5 {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
._3By-5 ._2V9Ge {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
._p5wgA {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

._p5wgA:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

._2V9Ge:checked + ._p5wgA {
  background-color: black;
}

._2V9Ge:focus + ._p5wgA {
  box-shadow: 0 0 1px black;
}

._2V9Ge:checked + ._p5wgA:before {
  transform: translateX(13px);
}

/* Rounded sliders */
._p5wgA {
  border-radius: 17px;
}

._p5wgA:before {
  border-radius: 50%;
}
._2Cux7 {
  background-color: black;
  height: 3.75em;
  width: 3.75em;
  border-radius: 3.75em;
  margin-bottom: 1em;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

._1SbA9 {
  position: absolute;
  transform: translateX(-100%);
  left: -1em;
  white-space: nowrap;
  overflow: hidden;
}

._31mwS {
  font-size: 1em !important;
  color: black !important;
}

._2Cux7 img {
  height: 90%;
  width: 90%;
}

._2Cux7 p {
  color: white;
  font-size: 1.5em;
}

._3M6C7 img {
  width: 100%;
  height: 100%;
}
._1pO8I {
  background-color: black;
  border-radius: 5em;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 5em;
  width: 100%;
  margin-bottom: 1.5em;
  -webkit-animation: _1Iv63 0.2s;
          animation: _1Iv63 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

._3fIXr {
  margin: 0;
  overflow: hidden;
  -webkit-animation: _AjEtt 0.2s;
          animation: _AjEtt 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes _AjEtt {
  from {
    width: 100%;
    height: 5em;
  }

  to {
    width: 0%;
    height: 0em;
  }
}

@keyframes _AjEtt {
  from {
    width: 100%;
    height: 5em;
  }

  to {
    width: 0%;
    height: 0em;
  }
}

@-webkit-keyframes _1Iv63 {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 5em;
  }
}

@keyframes _1Iv63 {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 5em;
  }
}

._1pO8I label {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

._1pO8I svg {
  height: 3em;
  width: 3em;
  margin: 1em;
  cursor: pointer;
}

._1pO8I form {
  width: 100%;
  height: 100%;
}

._1pO8I input {
  background-color: black;
  border: none;
  outline: none;
  color: white;
  font-size: 1em;
  height: 100%;
  width: calc(100% - 5em);
  box-sizing: border-box;
  border-radius: 5em;
}

._1pO8I input:focus {
  border: none;
  outline: none;
}

._TJkig {
  /* height: 6em; */
  /* width: 100%; */
  width: 75%;
  min-width: 18em;
  background-color: white;
  padding: 0.6em 0.5em 0.85em 1em;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0;
  border: 0;
  transition: 0.3s;
}

._e5V_9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._e5V_9 > span {
  font-size: 0.65em;
  font-weight: 600;
}

._32-XP {
  background-color: #8EF1FF;
  border-radius: 1em;
  font-size: 0.75em;
  /* width: 10em; */
  display: inline-block;
  padding: 0 1em;
}

._TJkig:hover {
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

._1JHlP {
  border: 1px solid #000000 !important;
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

._TJkig p {
  margin: 0;
}


._1b_TQ {
  height: 1.5em;
}

._1b_TQ svg {
  height: 1.5em;
  width: auto;
  margin-left: 0.5em;
}

._1b_TQ, ._1b_TQ div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._3IknF {
  display: flex;
  align-items: flex-start;
  height: 2em;
}

._3IknF p:first-child {
  margin-right: 5px;
}

._3IknF svg {
  height: 100%;
}

._3Mvny {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

._dkrwR {
  display: flex;
  align-items: center;
  min-width: 8em;
  width: 45%
}

._3xFKS {
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.5em;
  margin-right: 0.25em;
}

._PkZWh {
  background-color: #FF2D47;
}

._1cGyS {
  background-color: #F5A623;
}

._6P5_u {
  background-color: rgb(255, 239, 56);
}

._2tRVU {
  background-color: #B7E98C;
}

._JwXRJ {
  font-size: 1.5em;
}

.__o1oj {
  font-size: 0.65em;
}

._2kaV8 {
  font-weight: 600;
}
._1atDg {
  height: 6.5em;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 6.5em auto;
  grid-template-rows: 6.5em;
  cursor: pointer;
}

._1atDg:hover {
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

._1atDg p {
  margin: 0 0 5px 0 !important;
}

._ScENs {
  -webkit-animation: _1MW6v 0.3s;
          animation: _1MW6v 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  overflow: hidden;
}

._19lIr {
  height: 100%;
  width: 6.5em;
}

._19lIr img {
  height: 100%;
  width: auto;
}

._o2Dfh {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

._Ny7DF {
  margin-top: 0.5em;
  height: 0.65em;
}

._2ZpSt {
  /* width: calc(100% - 6.5em); */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

._1HcvE {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._brV0U {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._2F7se {
  width: 1.5em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._2F7se > div {
  height: 50%;
}

._1HcvE > p:first-child, ._brV0U > p:first-child {
  color: #A5A5A5;
  font-size: 0.75em;
}

._Y38br {
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
}

._S6IW6 {
  font-size: 0.65em;
  font-weight: 600;
}

._1E_Zo {
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.5em;
  margin-right: 0.25em;
}

._t8b_y {
  background-color: #FF2D47;
}

._1NC7y {
  background-color: #F5A623;
}

._111kO {
  background-color: rgb(255, 239, 56);
}

._cFU9d {
  background-color: #B7E98C;
}

._5jfV6 {
  display: flex;
}

@-webkit-keyframes _1MW6v {
  from {
    max-height: 0;
  }

  to {
    max-height: 6.5em;
  }
}

@keyframes _1MW6v {
  from {
    max-height: 0;
  }

  to {
    max-height: 6.5em;
  }
}

._1PLYl {
  -webkit-animation: _3VtKx 0.3s;
          animation: _3VtKx 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  overflow: hidden;
}

@-webkit-keyframes _3VtKx {
  from {
    max-height: 6.5em;
  }

  to {
    max-height: 0;
  }
}

@keyframes _3VtKx {
  from {
    max-height: 6.5em;
  }

  to {
    max-height: 0;
  }
}


._314Di {
  height: 0.65em;
  width: 100%;
  display: flex;
}

._314Di > div {
  height: 100%;
  border-radius: 0.65em;
}

._sbLXB {
  background-color: #FF2D47;
}

._2mSR4 {
  background-color: #F5A623;
}

._1YJfc {
  background-color: rgb(255, 239, 56);
}

._25gIz {
  background-color: #B7E98C;
}
._Kyo7h {
  display: flex;
  align-items: center;
}

._3cr1p {
  font-size: 1em;
  border: 1px solid #9B9B9B;
  border-top: none;
  border-right: none;
  /* border-left: none; */
  padding: 0.5em;
  margin: 0 0.5em 1em;
  box-sizing: border-box;
  display: block;
  width: 33%;
  background-color: rgba(255, 255, 255, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

._Kyo7h input:first-child {
  margin-left: 0;
  /* border-left: 1px solid #9B9B9B; */
}

._Kyo7h input:last-child {
  margin-right: 0;
}

._3cr1p:focus {
  outline: none;
}

._2oWxD {
  text-align: left;
  background-color: none;
}
._2FRmk p {
  margin: 0;
}

._2FRmk button {
  height: 2.25em;
}

._2mqCT {
  display: flex;
  justify-content: space-between;
}
._j593g {
  width: 7.5em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 2em;
}

._j593g p {
  font-weight: 600;
  margin: 0;
}
._3iqtj {
  position: relative;
  background-color: white;
  display: flex;
  border-radius: 200px 0 0 200px;
  height: 6em;
  width: 100%;
  margin-bottom: 1em;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

._3yy6_ {
  border: 1px solid black;
}

._3iqtj p {
  margin: 0;
}

._3iqtj ._3sWL5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5em 0;
}

._3iqtj ._34R1i {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  width: calc(6em - 10px);
  height: calc(6em - 10px);
  min-width: calc(6em - 10px);
  background-color: rgb(68, 68, 68);
  margin-right: 1em;
}

._3iqtj ._34R1i img {
  height: 100%;
  width: auto;
  border-radius: 100%;
}

._3iqtj ._34R1i p {
  font-size: 1.5em;
  color: white;
}

._296di {
  font-size: 0.75em;
}

._1uBxu {
  background-color: rgb(141, 241, 255);
  border-radius: 2em;
  display: inline-flex;
  padding: 0 0.75em;
}

._O0I6- {
  font-size: 0.75em;
  display: inline-block;
}
._Upgj9 {
  position: absolute;
  top: 0;
  width: 12.5em;
  left: calc(14.5em + 10px);
  height: 3em;
  border: 1px solid black;
  cursor: pointer;
  padding: 0.5em;
  box-sizing: border-box;
  z-index: 3;
  background-color: white;
  display: flex;
  align-items: center;
}

._1xHm3 {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: black;
  top: -1px;
  left: -5px;
}

._Upgj9 p {
  margin: 0;
}
._1jijb {
  width: 100%;
  height: 1em;
  border-radius: 1em;
  position: relative;
  background-color: white;
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

._1jijb > div {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #8EF1FF;
  border-radius: 1em 0 0 1em;
  border: 1px solid white;
  border-right: 1px solid black;
  height: 1em;
}
._25YhX {
  position: absolute;
  width: 1em;
  height: 1em;
  top: -1em;
  left: -1em;
  background-color: black;
  border: 1px solid #DFDFDF;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

._25YhX svg {
  width: 100%;
  height: 100%;
}
._2QLNO {
  position: fixed;
  top: 0.5em;
  right: 0.5em;
  box-shadow: 2px 2px 7px 0 rgba(0,0,0,0.5);
  background-color: black;
  padding: 0.5em;
  -webkit-animation: _FHBWp 0.3s forwards;
          animation: _FHBWp 0.3s forwards;
  z-index: 10;
  width: 16em;
}

._19n0m {
  top: 50%;
  right: 50%;
  -webkit-animation: _3cxxJ 0.3s forwards;
          animation: _3cxxJ 0.3s forwards;
}

._3dEEG {
  position: relative;
}

._p4snT {
  display: flex;
}

._2QLNO p {
  color: #DFDFDF;
}

._WO-hY {
  margin: 0 0 0.5em;
}

._2i7Ko {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  height: 2em;
  margin-bottom: 0.5em;
}

._2i7Ko img {
  height: 2em;
}

._2i7Ko p {
  font-size: 0.75em;
  margin: 0;
}

._1rAck {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(150, 150, 150, 0.9);
  z-index: 9;
}

._HTSEQ {
  -webkit-animation: _HTSEQ 0.3s forwards;
          animation: _HTSEQ 0.3s forwards;
}

._2dNtU {
  -webkit-animation: _2dNtU 0.3s forwards;
          animation: _2dNtU 0.3s forwards;
}

@-webkit-keyframes _FHBWp {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes _FHBWp {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes _HTSEQ {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes _HTSEQ {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@-webkit-keyframes _3cxxJ {
  from {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }

  to {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }
}

@keyframes _3cxxJ {
  from {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }

  to {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }
}

@-webkit-keyframes _2dNtU {
  from {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }

  to {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }
}

@keyframes _2dNtU {
  from {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }

  to {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }
}





._1siSt {
  position: fixed;
  z-index: 10;
  width: 30em;
}

._1siSt {
  top: 50%;
  right: 50%;
  -webkit-animation: _1eCOy 0.3s forwards;
          animation: _1eCOy 0.3s forwards;
}

._1uZ6V {
  height: 100%;
  background-color: black;
  box-shadow: 2px 2px 7px 0 rgba(0,0,0,0.5);
  min-height: 8em;
  padding: 0.5em;
}

._36EeU {
  position: relative;
}

._30uZd {
  display: flex;
  width: 100%;
  margin-top: 0.5em;
}

._1siSt p {
  color: #DFDFDF;
}

._3MQH8 {
  margin: 0 0 0.5em;
}

._QHVJk {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  height: 2em;
  margin-bottom: 0.5em;
}

._QHVJk img {
  height: 2em;
}

._QHVJk p {
  font-size: 0.75em;
  margin: 0;
}

._1AKeQ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(150, 150, 150, 0.9);
  z-index: 9;
}

._X0CRB {
  -webkit-animation: _X0CRB 0.3s forwards;
          animation: _X0CRB 0.3s forwards;
}

._2tfsw {
  -webkit-animation: _2tfsw 0.3s forwards;
          animation: _2tfsw 0.3s forwards;
}

@-webkit-keyframes _b79Px {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes _b79Px {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes _X0CRB {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes _X0CRB {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@-webkit-keyframes _1eCOy {
  from {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }

  to {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }
}

@keyframes _1eCOy {
  from {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }

  to {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }
}

@-webkit-keyframes _2tfsw {
  from {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }

  to {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }
}

@keyframes _2tfsw {
  from {
    transform: translate(50%, -50%);
    opacity: 1;
    right: 50%;
  }

  to {
    transform: translate(100%, -50%);
    opacity: 0;
    right: 0%;
  }
}
._1vNlA {
  height: 6.5em;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
}

._1vNlA:hover {
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

._2hPtJ {
  height: 6.5em;
  width: 26em;
  background-color: white;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
}

._pT3PW {
  height: 100%;
  width: auto;
}

._1vNlA p {
  margin: 0 0 5px 0 !important;
}

._rY2yA {
  -webkit-animation: _uG790 0.3s;
          animation: _uG790 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  /* overflow: hidden; */
}

._3VHz_ {
  /* margin-top: 3px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  height: 100%;
  width: 6.5em;
}

._1Yvoi {
  display: flex;
  flex-direction: row;
  height: 6.4em;
  width: 6.4em;
  border-style: solid;
}

._1bff2 {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  /* height: calc(100% - 6px); */
  height: 100%;
  width: 13em;
}

/* Hide scrollbar for Chrome, Safari and Opera */
._1bff2::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
._1bff2 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

._1coLa {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  overflow: scroll;
  height: 100%;
  width: 6.5em;
}

._35g_g {
  height: 5em;
  width: 5em;
}

._3VHz_ img {
  height: 100%;
  width: auto;
}

._p97fJ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._1HzNM {
  margin-top: 0.5em;
  height: 0.65em;
}

._1NKbx {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

._17Ix1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._2j_TZ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15%;
}

._1aZIS {
  right: 0.5em;
  width: 1.5em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._1aZIS > div {
  height: 50%;
}

._17Ix1 > p:first-child, ._2j_TZ > p:first-child {
  color: #A5A5A5;
  font-size: 0.75em;
}

._24ZBL {
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
}

._3RNz6 {
  font-size: 0.65em;
  font-weight: 600;
}

._2egO4 {
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.5em;
  margin-right: 0.25em;
}

._3Kw4x {
  background-color: #FF2D47;
}

._3_kCM {
  background-color: #F5A623;
}

._24kmt {
  background-color: rgb(255, 239, 56);
}

._83Ozv {
  background-color: #B7E98C;
}

._2UKRq {
  display: flex;
}

@-webkit-keyframes _uG790 {
  from {
    max-height: 0;
  }

  to {
    max-height: 6.5em;
  }
}

@keyframes _uG790 {
  from {
    max-height: 0;
  }

  to {
    max-height: 6.5em;
  }
}

._3m_L6 {
  -webkit-animation: _31YD8 0.3s;
          animation: _31YD8 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  overflow: hidden;
}

@-webkit-keyframes _31YD8 {
  from {
    max-height: 6.5em;
  }

  to {
    max-height: 0;
  }
}

@keyframes _31YD8 {
  from {
    max-height: 6.5em;
  }

  to {
    max-height: 0;
  }
}


._1JOxQ > p {
  margin: 0;
}

._2sx8E {
  display: flex;
  justify-content: space-between;
}
._1xlZ6 {
  width: 100%;
  display: flex;
  /* height: 1px; */
}

._3h4kj {
  border-bottom: 1px solid black;
  position: relative;
}

._3ljt5 {
  border-bottom: 1px solid black;
  position: relative;
  -webkit-animation: _1GxwY;
          animation: _1GxwY;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

@-webkit-keyframes _1GxwY {
  from {
    max-width: 0;
  }

  to {
    max-width: 100%;
  }
}

@keyframes _1GxwY {
  from {
    max-width: 0;
  }

  to {
    max-width: 100%;
  }
}

._2NK8s {
  border-bottom: 1px solid rgb(203, 203, 203);
  position: relative;
}

._cXyVy {
  height: 16px;
  width: 16px;
}

._Vhcqb {
  height: 16px;
  width: 16px;
  border-radius: 100%;
}

._2KGgl {
  background: black;
}

._3G6si {
  background: rgb(203, 203, 203);
}

._2KGgl, ._3G6si {
  position: absolute;
  right: 0;
  bottom: -8px;
}

._2DsQL {
  position: absolute;
  left: 0;
  bottom: -8px;
}


._3dJNt {
  -webkit-appearance: none;  /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  /* background: #d3d3d3; Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 49%, rgb(65, 65, 65) 51%, rgb(85, 85, 85) 100%);
}

._3dJNt::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 12px; /* Set a specific slider handle width */
  /* height: 30px; Slider handle height */
  height: 27px;
  background: rgb(85, 85, 85);
  border-radius: 8px;
  border: 1px solid rgb(160, 160, 160);
  /* margin-bottom: 5px; */
  cursor: pointer; /* Cursor on hover */
}

._3dJNt::-moz-range-thumb {
  width: 10px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgb(85, 85, 85);
  border: 1px solid rgb(160, 160, 160);
  cursor: pointer; /* Cursor on hover */
}